import request from '@/utils/request'

const contractApi = {
  contract_list: '/contract/template/',
  contract_create: '/contract/template/',
  contract_template_read: '/contract/template/',
  contract_delete: '/contract/template/'
}

/**
 * 列表
 */
export function contract_list (parameter) {
  return request({
    url: contractApi.contract_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function contract_create (parameter) {
  return request({
    url: contractApi.contract_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 详情
 */
export function contract_template_read (contract_id) {
  return request({
    url: contractApi.contract_template_read + contract_id + '/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 删除
 */
export function contract_delete (contract_id) {
  return request({
    url: contractApi.contract_delete + contract_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
