<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="合同模版">
              <a-select
                v-decorator="[
                  'contract_template_id',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ]
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="(item, index) in contractList" :value="item.id" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="corporation_id" v-show="false">

              <a-input v-decorator="['corporation_id']" />

            </a-form-item>
            <a-form-item label="企业名称">
              <a-auto-complete
                v-decorator="['corporation', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <div class="ant-form-explain" v-if="!!tip">  {{ tip }} </div>
            </a-form-item>

            <a-form-item label="开始时间" extra="格式: YYYY-mm-dd">
              <a-date-picker
                v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledStartDate"
                show-time
                format="YYYY-MM-DD"
                placeholder="开始时间"
                @openChange="handleStartOpenChange"
              />
            </a-form-item>
            <a-form-item label="结束时间" extra="格式: YYYY-mm-dd">
              <a-date-picker
                v-decorator="['end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                :disabled-date="disabledEndDate"
                show-time
                format="YYYY-MM-DD"
                placeholder="结束时间"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </a-form-item>
            <!--            <a-form-item label="厂房面积" extra="单位:平米">
              <a-input v-decorator="['plant_area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="办公面积" extra="单位:平米">
              <a-input v-decorator="['official_area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="办公室租金" extra="单位:元/平米/天">
              <a-input v-decorator="['official_rent', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="厂房租金" extra="单位:元/平米/天">
              <a-input v-decorator="['plant_rent', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>-->
            <a-col :span="12">
              <a-form-item label="租期">
                <a-input v-decorator="['duration', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="办公物业费" extra="单位:元/100/平米/天">
                <a-input v-decorator="['official_property', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="厂房物业费" extra="单位:元/100/平米/天">
                <a-input v-decorator="['plant_property', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="押金">
                <a-input v-decorator="['deposit', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="付款周期">
                <a-input v-decorator="['payment_period', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="年增长百分比" extra="%">
                <a-input v-decorator="['year_increase', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="备注">
                <a-input v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
            </a-col>
          </a-col></a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { contract_list } from '@/api/contract'
import { corporation_list } from '@/api/corporation'
// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      endOpen: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      contractList: [],
      tip: '',
      dataSource: [],
      content: '',
      corList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.getcontract()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    disabledStartDate (startValue) {
      const endValue = this.form.getFieldValue('end_date')
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate (endValue) {
      const startValue = this.form.getFieldValue('start_date')
      console.log(startValue.valueOf())
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange (open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getcontract () {
      contract_list({})
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          this.contractList = data
        })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.find((item) => (item.name === value) && item).id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    }
  }
}
</script>
<style>
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 0px;
  }
  .ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    line-height: 30px;
  }
</style>
