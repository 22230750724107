<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select default-value="" style="width: 100%" v-model="queryParam.status">
                <a-select-option key="" value="">
                  全部
                </a-select-option>
                <a-select-option v-for="(value, key) in this.$Dictionaries.ContractStatus" :key="key" :value="key">
                  {{ value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="privew(record)">查看</a>
        </template>
      </span>
    </s-table>
    <a-modal
      title="按时间生成账单"
      v-if="billvisble"
      :width="1080"
      :visible="billvisble"
      :confirm-loading="billLoading"
      @ok="handleBillOk"
      @cancel="handleBillCancel"
    >
      <a-form style="width: 100%">
        <a-row :gutter="48">
          <a-col :md="24" :sm="24">
            <a-form-item label="时间" style="width: 100%">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 60%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <preview
      v-if="previsible"
      ref="preview"
      :visible="previsible"
      :loading="preLoading"
      :content="content"
      @cancel="handlepreCancel"
      @ok="handlepreOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  rent_contract_list,
  rent_contract_create,
  rent_contract_delete,
  rent_contract_partial_update,
  rent_contract_properties
} from '@/api/rent_contract'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import Preview from '../modules/preview'
import { contract_template_read } from '@/api/contract'
import { rent_bill_create } from '@/api/rent_bill'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    Preview
  },
  data () {
    return {
      arrstr: '',
      current_contract_id: '',
      billvisble: false,
      billLoading: false,
      billTime: {},
      content: '',
      previsible: false,
      loading: false,
      confirmLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '开始日期',
          dataIndex: 'start_date',
          ellipsis: true
        },
        {
          title: '结束日期',
          dataIndex: 'end_date',
          ellipsis: true
        },
       /* {
          title: '办公租金(元)',
          dataIndex: 'official_rent',
          customRender: (text) => (text / 100).toFixed(2),
          ellipsis: true
        },
        {
          title: '办公物业费(元)',
          dataIndex: 'official_property',
          customRender: (text) => (text / 10000).toFixed(4),
          ellipsis: true
        },
        {
          title: '厂房租金(元)',
          dataIndex: 'plant_rent',
          customRender: (text) => (text / 100).toFixed(2),
          ellipsis: true
        },
        {
          title: '厂房物业费(元)',
          dataIndex: 'plant_property',
          customRender: (text) => (text / 10000).toFixed(4),
          ellipsis: true
        },
        {
          title: '厂房面积',
          dataIndex: 'plant_area',
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        }, */
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.ContractStatus[text],
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        console.log(this.$Dictionaries.ContractStatus, ' 我是合同状态')
        return rent_contract_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries, ' 我是合同状态')
  },
  methods: {
    rent_contract_partial_update (record) {
      rent_contract_partial_update({ status: record.status }, record.id).then((res) => {
        console.log(res, '我是修改状态')
      })
    },
    privew (record) {
      rent_contract_properties(record.id).then(({ data }) => {
        console.log(data, 123123, record)
        let arrstr = ''
        const values = Object.assign({}, record)
        values.start_date = moment(values.start_date).format('YYYY年MM月DD日')
        values.end_date = moment(values.end_date).format('YYYY年MM月DD日')
        values.deposit = (values.deposit / 100).toFixed(2)
        data.forEach((data, index) => {
          arrstr += '</br>' + data.zone_property_cell__zone_property__name + '-' + data.zone_property_cell__name + '，租赁面积：' + data.used + '(平方米)' + '，物业费用：' + (data.property_price / 10000).toFixed(4) + '(元)/平米/天' + '，租金费用：' + (data.rental_price / 100).toFixed(2) + '(元)/平米/天'
        })
        values.rent_address = arrstr
        console.log('form, 数据', values)
        contract_template_read(values.contract_template_id).then(({ data }) => {
          console.log(data, '我是模板')
          const beforedata = data.content.replace(/\{(\w+)\}/gi, function (a, b) {
            console.log(values[b], '替换')
            return values[b]
          })
          this.previsible = true
          this.content = beforedata
          console.log(this.content, '我到底是个啥')
        })
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handlepreCancel () {
      this.previsible = false
    },
    handlepreOk () {
      this.previsible = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      rent_contract_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    createBill (record) {
      rent_bill_create({ create_mode: 1, contract_id: record.id }).then((res) => {
        if (res.success) {
          this.$message.success('成功')
        }
      })
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.billTime.start_date = dateStrings[0]
      this.billTime.end_date = dateStrings[1]
    },
    timeCreateBill (record) {
      this.current_contract_id = record.id
      this.billvisble = true
      this.billLoading = false
    },
    handleBillOk () {
      const param = Object.assign({}, this.billTime, { create_mode: 2, contract_id: this.current_contract_id })
      rent_bill_create(param).then((res) => {
        console.log(res)
        this.billvisble = false
        this.billLoading = false
        if (res.success) {
          this.$message.success('成功')
        }
      })
    },
    handleBillCancel () {
      this.billvisble = false
      this.billLoading = false
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          values.deposit = Math.round(values.deposit * 100)
          values.official_rent = Math.round(values.official_rent * 100)
          values.plant_rent = Math.round(values.plant_rent * 100)
          values.official_property = Math.round(values.official_property * 10000)
          values.plant_property = Math.round(values.plant_property * 10000)

          // 新增
          rent_contract_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
           }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
